// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Cx from "rescript-classnames/src/Cx.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Control from "../../../../styleguide/components/Control/Control.res.js";
import * as IconClose from "../../../../styleguide/icons/IconClose.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ConfirmModalScss from "./ConfirmModal.scss";

var css = ConfirmModalScss;

function ConfirmModal(props) {
  var serverError = props.serverError;
  var close = props.close;
  var confirm = props.confirm;
  var mobile = props.mobile;
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      className: css.modalOverlay,
                      onClick: (function (param) {
                          close();
                        })
                    }),
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Control.make, {
                                    className: css.closeOverlayButton,
                                    onClick: (function (param) {
                                        close();
                                      }),
                                    children: JsxRuntime.jsx(IconClose.make, {
                                          title: "Close",
                                          size: "MD",
                                          color: "Gray"
                                        })
                                  }),
                              JsxRuntime.jsx("div", {
                                    children: props.message,
                                    className: css.title
                                  }),
                              serverError !== undefined ? JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx("div", {
                                            children: JsxRuntime.jsx(P.make, {
                                                  children: serverError
                                                }),
                                            className: css.errors
                                          }),
                                      className: css.errorContainer
                                    }) : null,
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Button.make, {
                                            size: mobile ? "SM" : "MD",
                                            color: "Gray",
                                            expanded: false,
                                            submit: false,
                                            className: css.buttonItem,
                                            onClick: (function (param) {
                                                close();
                                              }),
                                            children: "Cancel"
                                          }),
                                      JsxRuntime.jsx(Button.make, {
                                            size: mobile ? "SM" : "MD",
                                            color: "Primary",
                                            expanded: false,
                                            submit: false,
                                            className: css.buttonItem,
                                            onClick: (function (param) {
                                                confirm();
                                              }),
                                            children: "Confirm"
                                          })
                                    ],
                                    className: css.cartButton
                                  })
                            ],
                            className: css.modalContents
                          }),
                      className: css.sideModal,
                      id: "sideModal"
                    })
              ],
              className: Cx.cx([css.sideModalContainer]),
              id: "sideModalContainer"
            });
}

var make = ConfirmModal;

export {
  css ,
  make ,
}
/* css Not a pure module */
